<template>
	<div class="main-view">
		<login />
	</div>
</template>

<script>
import Login from '@/components/login'

export default {
	name: 'view-login',
	components: { Login }
}
</script>

<style lang="stylus" scoped>

.main-view
	width 100%
	height 100%
	
</style>
